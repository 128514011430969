import React, { useState, useEffect } from "react";
import ImageBase from "../../common/Control/ImageBase";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
const topbarTitles = [
  {
    pathname: "/trang-chu",
    isGoBack: false,
    title: "Trang chủ",
  },
  {
    pathname: "/Home/ChangePass",
    isGoBack: false,
    title: "Đổi mật khẩu",
  },
  {
    pathname: "/Home/CreateUser",
    isGoBack: false,
    title: "Tạo mới User",
  },
  {
    pathname: "/nhap-kho/tao-kien-hang",
    isGoBack: false,
    title: "Nhập kho - tạo kiện hàng",
  },
  {
    pathname: "/nhap-kho/tim-kiem-van-don",
    isGoBack: false,
    title: "Tìm kiếm vận đơn",
  },
  {
    pathname: "/nhap-kho/nhap-kho-vn",
    isGoBack: false,
    title: "Nhập kho Việt Nam",
  },
  {
    pathname: "/dong-bao/tao-bao",
    isGoBack: false,
    title: "Đóng bao - tạo bao",
  },
  {
    pathname: "/dong-bao/danh-sach-bao",
    isGoBack: false,
    title: "Tìm kiếm bao",
  },
  {
    pathname: "/dong-bao/xep-xe",
    isGoBack: false,
    title: "Xếp xe",
  },
  {
    pathname: "/quan-ly-khach-hang",
    isGoBack: false,
    title: "Quản lý khách hàng",
  },
  {
    pathname: "/tra-cuu-van-don",
    isGoBack: false,
    title: "Tra cứu mã vận đơn",
  },
  {
    pathname: "/tra-cuu-bao",
    isGoBack: false,
    title: "Tra cứu mã bao",
  },
];
// /ho-so-noi-tru/:id
const PageTopBar = (props) => {
  const [pathName, setPathname] = useState(() => {
    let pathNameTemp = props.location.pathname;
    let pathNameSpecifyName = pathNameTemp.split("/");
    if (pathNameSpecifyName.length > 2) {
      pathNameTemp = "/" + pathNameSpecifyName[1];
    }
    return pathNameTemp;
  });
  const handleBackPage = () => {
    props.history.goBack();
  };
  useEffect(() => {
    let pathNameTemp = props.location.pathname;
    setPathname(pathNameTemp);
  }, [props.location.pathname]);
  if (pathName !== "/trang-chu")
    return (
      <div>
        <div className="page-top-bar">
          <div className="title-page">
            {topbarTitles.find((x) => x.pathname == pathName)?.title}
          </div>
        </div>
      </div>
    );
};

export default connect(null, null)(withRouter(PageTopBar));
