import { Tooltip } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import CommonBase from "../../../share/base/network/axiosNetwork";
import ImageBase from "./../../common/Control/ImageBase";
const sidebarItems = [
  {
    to: "/trang-chu",
    title: "Trang chủ",
    icon: "/assets/img/icon/home-outlined.svg",
  },
  {
    to: "/admin",
    title: "Thống kê giao dịch",
    icon: "/assets/img/icon/medical.svg",
  },
  {
    to: "/danh-sach-khach",
    title: "Danh sách khách",
    icon: "/assets/img/icon/menuhome.svg",
  },
  {
    to: "/quan-ly-stk",
    title: "Quản lý stk ngân hàng",
    icon: "/assets/img/icon/menuhome.svg",
  },
  {
    to: "/danh-sach-tai-khoan",
    title: "Danh sách tài khoản",
    icon: "/assets/img/icon/menuhome.svg",
  },
  {
    to: "/danh-sach-rut-tien",
    title: "Danh sách rút tiền",
    icon: "/assets/img/icon/medical.svg",
  },
  {
    to: "/danh-sach-nap-tien",
    title: "Danh sách nạp tiền ",
    icon: "/assets/img/icon/hospital-bed.svg",
  },
];
function Sidebar(props) {
  let pathName = props?.location?.pathname;
  return (
    <div
      className={
        "col-auto col-md-3 col-xl-2 px-sm-2 px-0 slidebar-pvi  " +
        (props.sidebarOpen ? "sidebar" : "sidebar-expand")
      }
    >
      <input type="hidden" id="buger" />
      {props.sidebarOpen ? (
        <div
          className="user-infomation px-3 py-3 pb-0"
          style={{ color: "white" }}
        >
          {props?.loginReducer?.userInfo?.ten_user}{" "}
          {props?.loginReducer?.userInfo?.chuyen == "True" &&
          props?.loginReducer?.userInfo?.duyet == "False"
            ? " - Cán bộ"
            : props?.loginReducer?.userInfo?.duyet == "True" &&
              props?.loginReducer?.userInfo?.chuyen == "True"
            ? " - Lãnh Đạo"
            : ""}
        </div>
      ) : null}

      <div
        style={{ marginTop: "0" }}
        className="px-3 pt-2 text-white min-vh-100"
      >
        {sidebarItems.map((item, index) => {
          return (
            <Tooltip key={index} title={item.title}>
              <Link
                to={item.to}
                className={
                  "pvi-menu-item item " +
                  (pathName == item.to ? "itemActive" : "")
                }
              >
                <ImageBase className="me-3 " url={item.icon} />
                <span className="fs-5 d-none d-sm-inline text-menu ">
                  {item.title}
                </span>
              </Link>
            </Tooltip>
          );
        })}
        <div className="dropdown pb-4">
          <a
            href="#"
            onClick={() => CommonBase.setLogOut()}
            className="pvi-log-out-btn d-flex align-items-center text-white text-decoration-none"
            id="log-out-button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="mx-1 log-out " id="log-out-text">
              Đăng xuất
            </span>
            <svg
              onClick={() => CommonBase.setLogOut()}
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className="bi bi-box-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"
              />
              <path
                fillRule="evenodd"
                d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  let { userInfo } = state.loginReducer?.toJS();
  return {
    loginReducer: {
      userInfo,
    },
  };
};
export default connect(mapStateToProps, null)(withRouter(Sidebar));
