import React from 'react'
export default function LoadingJs() {
  return (
    <div
      className="loading"
      id="loading-main"
      style={{
        display: 'none',
      }}
    >
      Loading&#8230;
    </div>
  );
}
