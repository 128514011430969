import React from 'react';
import { connect } from "react-redux";
import { Redirect, Route } from 'react-router-dom';



function PrivateRoute(props) {
    const { RouteComp, authenticated, ...rest } = props;
    const previousLocation = props.location;
    return (<div className="">
        <div className="main-body">
            <Route  {...rest}
                render={props =>
                    authenticated == undefined || authenticated == false
                        ?
                        <Redirect to={{ pathname: "/dang-nhap", state: { from: previousLocation } }} />
                        :
                        <RouteComp {...props} />
                }
            />
        </div>
    </div>

    )
}

const mapStateToProps = (state) => {
    let { authenticated } = state.loginReducer.toJS();
    return {
        authenticated
    };
};

export default connect(
    mapStateToProps,
    null
)(PrivateRoute);

