import { configureStore } from "@reduxjs/toolkit";

import loginReducer from "../components/screen/login/redux/loginReducer";

export const store = configureStore({
  reducer: {
    loginReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});
