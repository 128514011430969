import React from 'react';
import { connect } from "react-redux";
import { Redirect, Route } from 'react-router-dom';

function DefaultRoute(props) {
    const { authenticated, ...rest } = props;

    return (<Route {...rest}
        render={({ location }) =>
            <Redirect to={{ pathname: "/trang-chu", state: { from: location } }} />
        }
    />
    )
}

const mapStateToProps = (state) => {
    let { authenticated } = state.loginReducer.toJS();
    return {
        authenticated
    };
};

export default connect(
    mapStateToProps,
    null
)(DefaultRoute);
