import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Topbar from "../../layout/menu/Topbar";
import Sidebar from "./SideBarAdmin";
import PageTopBar from "../../layout/menu/PageTopBar";
class LayoutMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggled: false,
      isLoading: false,
      sidebarOpen:
        localStorage.getItem("sidebar") == null
          ? true
          : localStorage.getItem("sidebar") == "open"
          ? true
          : false,
    };
  }

  handleExpandSidebar() {
    let { sidebarOpen } = this.state;
    this.setState({ sidebarOpen: !sidebarOpen });
  }

  render() {
    return (
      <div>
        <div className="container-fluid px-0">
          <Topbar />
        </div>
        <div className="container-fluid">
          <div className="row flex-nowrap">
            {/* <Sidebar sidebarOpen={this.state.sidebarOpen} props={this.props} /> */}
            <div className="w-100">
              <PageTopBar />
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let { authenticated } = state.loginReducer.toJS();
  return {
    authenticated,
  };
};

export default connect(mapStateToProps, null)(withRouter(LayoutMain));
