import React, { useEffect, useState } from "react";
import ImageComponent from "../../common/Control/ImageBase";
import { Button, Drawer } from "antd";
import "./menu.scss";
import { Link, withRouter, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import CommonBase from "../../../share/base/network/axiosNetwork";
import { typeUser } from "../../../share/base/common/Contant";
const listMenuDefautl = [
  {
    id: 1,
    name: "Trang chủ",
    path: "/trang-chu",
    permission: [typeUser.admin, typeUser.customer],
  },
  {
    id: 2,
    name: "Quản lí khách hàng 客户",
    path: "/quan-ly-khach-hang",
    permission: [typeUser.admin, typeUser.userSysterm],
  },
  {
    id: 3,
    name: "Nhập kho 入库",
    path: "/nhap-kho",
    permission: [typeUser.admin, typeUser.userSysterm],
    child: [
      {
        id: 1,
        name: "Tạo kiện 添加包裹",
        path: "/nhap-kho/tao-kien-hang",
        permission: [typeUser.admin, typeUser.userSysterm],
      },
      {
        id: 2,
        name: "Tạo bao 集包",
        path: "/dong-bao/tao-bao",
        permission: [typeUser.admin, typeUser.userSysterm],
      },
      // {
      //   id: 3,
      //   name: "Kiểm tra Data",
      //   path: "/nhap-kho/tao-kien-hang",
      // },
    ],
  },

  {
    id: 4,
    name: "Xếp xe",
    path: "/dong-bao",
    permission: [typeUser.admin, typeUser.userSysterm],
    child: [
      {
        id: 1,
        name: "Danh sách bao 包清单",
        path: "/dong-bao/danh-sach-bao",
        permission: [typeUser.admin, typeUser.userSysterm],
      },
      {
        id: 2,
        name: "Xếp xe 装车",
        path: "/dong-bao/xep-xe",
        permission: [typeUser.admin, typeUser.userSysterm],
      },
    ],
  },
  {
    id: 5,
    name: "Nhập kho VN",
    path: "/nhap-kho/nhap-kho-vn",
    permission: [typeUser.admin, typeUser.userSysterm],
  },
  {
    id: 6,
    name: "Tra cứu mã vận đơn",
    path: "/tra-cuu-van-don",
    permission: [typeUser.admin, typeUser.customer, typeUser.userSysterm],
    child: [
      {
        id: 1,
        name: " Danh sách mã vận đơn 包裹清单",
        path: "/nhap-kho/tim-kiem-van-don",
        permission: [typeUser.admin, typeUser.userSysterm],
      },
      {
        id: 2,
        name: "Tra cứu mã vận đơn TQ",
        path: "/tra-cuu-van-don",
        permission: [typeUser.admin, typeUser.customer, typeUser.userSysterm],
      },
    ],
  },
  {
    id: 5,
    name: "Tra cứu mã bao",
    path: "/tra-cuu-bao",
    permission: [typeUser.admin, typeUser.customer, typeUser.userSysterm],
  },
];
function Topbar(props) {
  const [listMenu, setListMenu] = useState([]);
  let pathName = props?.location?.pathname;
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const showDrawer = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };
  const renderMenu = (menu) => {
    return menu.map((item, index) => {
      if (item?.child && item.child?.length > 0) {
        return (
          <li class="drop-down">
            <a
              className={
                pathName?.includes(item.path) ? "item-menu-active" : ""
              }
              href="#"
            >
              {item.name}
              <ImageComponent
                url="/assets/img/icon/dropdown.svg"
                style={{ paddingLeft: "4px", marginBottom: "2px" }}
              />
            </a>
            <ul>
              {item.child.map((element, index) => (
                <li
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Link to={element.path}>
                    {index + 1} . {element.name}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        );
      } else {
        return (
          <li class="none">
            <Link
              to={item.path}
              className={pathName == item.path ? "item-menu-active" : ""}
              onClick={() => {
                setOpen(false);
              }}
            >
              {item.name}
            </Link>
          </li>
        );
      }
    });
  };
  useEffect(() => {
    if (props.userInfo && props.userInfo?.is_admin) {
      let listMenuTemp = [];
      let typeAdmin = props.userInfo?.is_admin;
      if (typeAdmin == typeUser.customer) {
        // la khach hang
        listMenuDefautl.forEach((item) => {
          let checkPermission = item.permission.includes(typeUser.customer);
          if (checkPermission && checkPermission != undefined) {
            let temp = item;
            if (item.child && item.child?.length > 0) {
              temp.child = temp.child.filter((element) =>
                element.permission.includes(typeUser.customer)
              );
            }
            listMenuTemp.push(temp);
          }
        });
      } else {
        listMenuTemp = listMenuDefautl;
      }
      setListMenu(listMenuTemp);
    }
  }, [props?.userInfo]);
  return (
    <div
      id="header-sticky-wrapper"
      class="sticky-wrapper"
      style={{ height: "46px" }}
    >
      <header
        class="fixed-top no-printme"
        id="header"
        style={{
          borderBottom: "2px solid #2f7a58",
          backgroundColor: "#ffffff",
        }}
      >
        <div class="container d-flex" style={{ float: "left", width: "100%" }}>
          <nav class="nav-menu d-none d-lg-block">
            <ul>
              {renderMenu(listMenu)}
              <li class="drop-down">
                <a href="#">
                  <i class="glyphicon glyphicon-user"></i>{" "}
                  {props?.userInfo?.username}
                  <ImageComponent
                    url="/assets/img/icon/dropdown.svg"
                    style={{ paddingLeft: "4px", marginBottom: "2px" }}
                  />
                </a>
                <ul>
                  {/* <li>
                    <a href="/Home/CreateUser">Tạo User</a>
                  </li> */}
                  <li>
                    <a href="/Home/ChangePass">Đổi mật khẩu</a>
                  </li>

                  <li>
                    <a href="#" onClick={() => CommonBase.setLogOut()}>
                      Logout
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
        <Button type="primary d-block d-lg-none" onClick={showDrawer}>
          Menu
        </Button>
        <Drawer size={"default"} title="Menu" onClose={onClose} open={open}>
          <div
            class="container d-flex"
            style={{ float: "left", width: "100%" }}
          >
            <nav class="nav-menu ">
              <ul>
                {renderMenu(listMenu)}
                <li class="drop-down">
                  <a href="#">
                    <i class="glyphicon glyphicon-user"></i>{" "}
                    {props?.userInfo?.username}
                    <ImageComponent
                      url="/assets/img/icon/dropdown.svg"
                      style={{ paddingLeft: "4px", marginBottom: "2px" }}
                    />
                  </a>
                  <ul>
                    {/* <li>
                      <a href="/Home/CreateUser">Tạo User</a>
                    </li> */}
                    <li>
                      <a href="/Home/ChangePass">Đổi mật khẩu</a>
                    </li>

                    <li>
                      <a href="#" onClick={() => CommonBase.setLogOut()}>
                        Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </Drawer>
      </header>
    </div>
  );
}

const mapStateToProps = (state) => {
  let { userInfo } = state.loginReducer.toJS();
  return {
    userInfo: userInfo?.userInfo,
  };
};
export default connect(mapStateToProps)(withRouter(Topbar));
