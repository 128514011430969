import React, { lazy, Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  AUTH_USER,
  UN_AUTH_USER,
} from "../src/components/screen/login/redux/loginReducer";
import LoadingJs from "./components/common/loadingJs";
import DefaultRoute from "./components/routes/default-route";
import PublicRoute from "./components/routes/public-route";
import PrivateRoute from "./components/routes/private-route";
import { CookiesHelper } from "./share/base/common/cookiesHelper";
import { loginType } from "./share/base/constants/login";
import "./share/css/App.scss";
import { closeLoading, openLoading } from "./share/base/common/LoadingAction";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CommonBase from "./share/base/network/axiosNetwork";
import { GET_INFOR_USER } from "./components/screen/ClientManager/UserInfor/UserInforAPI";

import LayoutAdmin from "./components/screen/AdminManager/lay-out-admin";

const Home = lazy(() => import("./components/screen/home/home"));
const Login = lazy(() => import("./components/screen/login/login"));
const DoiMatKhau = lazy(() =>
  import("./components/screen/UserManager/DoiMatKhau")
);
const TaoUser = lazy(() => import("./components/screen/UserManager/TaoUser"));
const TaoKienHang = lazy(() =>
  import("./components/screen/NhapKho/TaoKienHang")
);

const TimKiemVanDon = lazy(() =>
  import("./components/screen/NhapKho/TimKiemVanDon/TimKiemVanDon")
);
const DanhSachBao = lazy(() =>
  import("./components/screen/DongBao/DanhSachBao/DanhSachBao")
);
const NhapKhoVN = lazy(() => import("./components/screen/NhapKho/NhapKhoVN"));

const TaoBao = lazy(() => import("./components/screen/DongBao/TaoBao"));

const XepXe = lazy(() => import("./components/screen/NhapKho/XepXe"));

const QuanLyKhachHang = lazy(() =>
  import("./components/screen/QuanLyKhachHang/IndexCustomer")
);

const TraCuuMaBao = lazy(() => import("./components/screen/TraCuu/TraCuuBao"));
const TraCuuVanDon = lazy(() =>
  import("./components/screen/TraCuu/TraCuuVanDon")
);

// const IndexUser = lazy(() =>
//   import("./components/screen/AdminManager/Home/IndexUser")
// );
function App(props) {
  const [isLoading, setIsLoading] = useState(true); // so the bao hiem

  useEffect(() => {
    let login = CookiesHelper.get(loginType.loginCookieName);
    if (login != undefined && login != null) {
      props.loginAction.AUTH_USER(JSON.parse(login));
      getInforUserService();
    } else {
      props.loginAction.UN_AUTH_USER();
    }
    setIsLoading(false);
    return () => {};
  }, []);
  const getInforUserService = async () => {
    openLoading();
    let response = await CommonBase.getAsync(GET_INFOR_USER);
    if (response.isSuccess) {
      let login = JSON.parse(CookiesHelper.get(loginType.loginCookieName));
      let data = { token: login.token, userInfo: response.data };
      props.loginAction.AUTH_USER(data); // set token vao header
    }
    closeLoading();
  };

  useState(() => {
    let login = CookiesHelper.get(loginType.loginCookieName);
    if (login) {
    }
  }, []);
  console.log(process.env.PUBLIC_URL);
  return (
    <div className="h-100">
      {isLoading === true ? (
        <div></div>
      ) : (
        <BrowserRouter
          basename={process.env.PUBLIC_URL}
          fallback={<div>Loading...</div>}
        >
          <LoadingJs />
          <Suspense>
            <Switch>
              <DefaultRoute exact path="/" />
              <PublicRoute exact path="/dang-nhap" RouteComp={Login} />
              <LayoutAdmin exact>
                <PrivateRoute exact path="/trang-chu" RouteComp={Home} />
                <PrivateRoute
                  exact
                  path="/Home/ChangePass"
                  RouteComp={DoiMatKhau}
                />
                <PrivateRoute
                  exact
                  path="/Home/CreateUser"
                  RouteComp={TaoUser}
                />
                <PrivateRoute
                  exact
                  path="/nhap-kho/tao-kien-hang"
                  RouteComp={TaoKienHang}
                />

                <PrivateRoute
                  exact
                  path="/nhap-kho/tim-kiem-van-don"
                  RouteComp={TimKiemVanDon}
                />
                <PrivateRoute
                  exact
                  path="/dong-bao/danh-sach-bao"
                  RouteComp={DanhSachBao}
                />
                <PrivateRoute
                  exact
                  path="/nhap-kho/nhap-kho-vn"
                  RouteComp={NhapKhoVN}
                />
                <PrivateRoute
                  exact
                  path="/dong-bao/tao-bao"
                  RouteComp={TaoBao}
                />
                <PrivateRoute exact path="/dong-bao/xep-xe" RouteComp={XepXe} />
                <PrivateRoute
                  exact
                  path="/quan-ly-khach-hang"
                  RouteComp={QuanLyKhachHang}
                />
                <PrivateRoute
                  exact
                  path="/tra-cuu-van-don"
                  RouteComp={TraCuuVanDon}
                />
                <PrivateRoute
                  exact
                  path="/tra-cuu-bao"
                  RouteComp={TraCuuMaBao}
                />
              </LayoutAdmin>
            </Switch>
          </Suspense>
          <ToastContainer />
        </BrowserRouter>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  // homeAction: bindActionCreators({ AUTH_USER, UN_AUTH_USER }, dispatch),
  loginAction: bindActionCreators({ AUTH_USER, UN_AUTH_USER }, dispatch),
});

export default connect(null, mapDispatchToProps)(App);
