import { createSlice } from "@reduxjs/toolkit";
import CommonBase from "../../../../share/base/network/axiosNetwork";
import Immutable from "immutable";
const initialState = Immutable.fromJS({
  userInfo: null,
  authenticated: false,
  isCallApiLogin: false,
});
// vai tro cua ham createSlice tuong tu nhu cac ham gan gia tri switch case trong reducer
export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    AUTH_USER: function (state, action) {
      CommonBase.setAuthHeader(action.payload.token); // mac du da set token tai luc login thanh cong - nen doan nay de backup
      return state.set("authenticated", true).set("userInfo", action.payload);
    },
    UN_AUTH_USER: function (state, action) {
      return state.set("authenticated", false);
    },
  },
});

// create action sau nay se dung dispath de tro vao
export const { AUTH_USER, UN_AUTH_USER } = loginSlice.actions;

export default loginSlice.reducer;
