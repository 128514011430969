import React from 'react';
import { connect } from "react-redux";
import { Route } from 'react-router-dom';

function PublicRoute(props) {
    const { RouteComp, authenticated, path, ...rest } = props;
    return (
        <Route {...rest}
            render={props =>
                <RouteComp {...props} />
            }
        />
    )
}
const mapStateToProps = (state) => {
    let { authenticated } = state.loginReducer.toJS();
    return {
        authenticated
    };
};

export default connect(
    mapStateToProps,
    null
)(PublicRoute);
